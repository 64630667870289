import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <h2>About Us</h2>
      <p>
        Welcome to <strong>Sab Reserve</strong>, your go-to platform for booking essential daily services from the comfort of your home. We connect you with the best service providers in your area, ensuring top-notch services whenever you need them.
      </p>
      <p>
        Whether it's a reliable electrician, a skilled tailor, a relaxing spa day, or any other local service, <strong>Sab Reserve</strong> has you covered. We prioritize quality and convenience, making your life easier by bringing these services to your fingertips.
      </p>
      <p>
        Our platform offers a wide range of services, including:
      </p>
      <ul>
        <li><strong>Real Estate:</strong> Book appointments with trusted agents for buying, selling, or renting properties.</li>
        <li><strong>Ticket Booking:</strong> Easily book tickets for movies, events, and travel.</li>
        <li><strong>Tailoring:</strong> Connect with experienced tailors for custom clothing needs.</li>
        <li><strong>Spa Services:</strong> Enjoy professional spa services at your convenience.</li>
        <li><strong>Electricians:</strong> Hire qualified electricians for all electrical needs.</li>
        <li><strong>Other Local Services:</strong> From carpentry to beauty treatments, find a variety of local services.</li>
      </ul>
      <p>
        Our user-friendly platform allows you to browse, book, and manage your service appointments with ease. Simply search for the service you need, select a provider, and book an appointment—all from the comfort of your home.
      </p>
      <p>
        At <strong>Sab Reserve</strong>, we are committed to excellent customer service and ensuring your satisfaction. Our network of trusted professionals is dedicated to delivering the best services to meet your needs.
      </p>
      <p>
        Thank you for choosing <strong>Sab Reserve</strong>. We look forward to serving you and making your daily life more convenient and stress-free.
      </p>
      <Link to="/services" className="services-link">Explore Services</Link>
    </div>
  );
}

export default About;
